import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">
            <div className="w-11/12 flex flex-col my-12">
                <h1 className="w-full mx-auto text-left text-blue-primary font-normal text-3xl lg:text-4xl mt-6 mb-8 lg:mx-0">
                    Privacy <span className="font-bold">Policy</span>
                </h1>

                <p className="w-full mx-auto text-center text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0 mb-4">
                    Informativa al trattamento dati personali<br />
                    ai sensi del Regolamento UE 679/2016 e del D. Lgs. 196/2003
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Villa Iris s.r.l. con sede legale in Piazza Gozzano, 1 – 10132 Torino, in qualità di {' '}
                    <span className="font-bold text-blue-primary">
                        Titolare del Trattamento,{' '}
                    </span>
                    informa che i dati acquisiti al momento della prenotazione ed accettazione saranno oggetto di trattamento nel rispetto della normativa vigente in materia. Il {' '}
                    <span className="font-bold text-blue-primary">
                        Titolare del Trattamento,{' '}
                    </span>
                    in qualità del suo legale rappresentate, può essere contattato tramite i seguenti recapiti:
                </p>

                <ul className="list-disc list-inside mb-4">
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>Email: </span>
                        <a href="mailto:privacy@villairis.srl" className="text-base font-medium text-blue-primary text-right no-underline">
                            privacy@villairis.srl
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>PEC: </span>
                        <a href="mailto:villairis@legalmail.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            villairis@legalmail.it
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>tel. </span>
                        <a href="tel:0119663700" className="text-base font-medium text-blue-primary text-right no-underline">
                            0119663700
                        </a>
                    </li>
                </ul>

                <p className="text-base font-normal text-black mb-4">
                    Il Titolare del trattamento ha designato un{' '}
                    <span className="font-bold text-blue-primary">Responsabile per la protezione dei dati{' '}</span>
                    (Data Protection Officer ), per eventuali richieste o chiarimenti:
                </p>

                <ul className="list-disc list-inside mb-4">
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>Email: </span>
                        <a href="mailto:villairis@dpo-sanita.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            villairis@dpo-sanita.it
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>PEC: </span>
                        <a href="mailto:dpo-sanita@legalmail.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            dpo-sanita@legalmail.it
                        </a>
                    </li>
                </ul>
                <div className="space-y-4 mb-4 mt-6">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY SERVIZI AMULATORIALI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.02+INFORMATIVA+PRIVACY+POLIAMBULATORIO.pdf" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>
                <div className="space-y-4 mb-2 mt-4">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY FORNITORI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.03+INFORMATIVA+PRIVACY+FORNITORI.pdf" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>
                <div className="space-y-4 mb-2 mt-4">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY CANDIDATI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.01A+INFORMATIVA+PRIVACY+CANDIDATI.pdf" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>

               {/* <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Base giuridica del trattamento dei dati</div>
                    <p className="text-base font-normal text-black">
                        Si rende noto che il trattamento dei dati personali effettuato per finalità di tutela della salute e dell’incolumità fisica dell’interessato o di terzi o della collettività è effettuato ai sensi dell’art. 6, primo comma, lett. a), e b) (trattamento effettuato previo consenso dell’interessato ovvero per l’esecuzione di un contratto di cui l’interessato è parte) nonché dell’art. 9, comma secondo, lettera h) e comma terzo del Regolamento UE 2016/679 (trattamento di dati particolari senza consenso per finalità di medicina preventiva, diagnosi, assistenza o terapia sanitaria o conformemente al contratto con un professionista della sanità) nonché dell’articolo 2 –septies del D. Lgs. n. 101/2018.
                    </p>
                </div>

                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Finalità del trattamento</div>
                    <p className="text-base font-normal text-black mb-2">
                        I dati personali richiesti, di natura amministrativa, sono utilizzati per l’espletamento delle operazioni di prenotazione, accettazione e consegna dei referti, i dati particolari relativi al Suo stato di salute sono utilizzati per poter erogare le prestazioni sanitarie.
                    </p>
                    <p className="text-base font-normal text-black">
                        Per i trattamenti in oggetto, Villa Iris s.r.l. si avvale di personale identificato e incaricato, nell’ambito delle finalità previste e in modo da garantire la massima sicurezza e riservatezza dei dati.
                    </p>
                </div>


                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Modalità del trattamento</div>
                    <p className="text-base font-normal text-black mb-2">
                        La informiamo che i dati personali verranno conservati negli archivi cartacei ed elettronici della Struttura (in modo tale da garantire la sicurezza, riservatezza, integrità e disponibilità dei dati) e trattati (con o senza l’ausilio di strumenti elettronici) con logiche strettamente correlate alle Finalità del trattamento sopra menzionate. La Struttura ha adottato gli opportuni accorgimenti atti a consentire l’accesso e/o l’utilizzo del dato ai soli operatori designati e specificamente autorizzati e che ne hanno la necessità in funzione della qualifica posseduta. Tutte le fasi in cui si articola il trattamento dei dati personali verranno curate esclusivamente da personale specificamente incaricato ed istruito dal Titolare o da suo delegato e risulteranno presidiate dal segreto professionale o dal segreto d’ufficio. In ogni caso il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate.
                    </p>
                    <p className="text-base font-normal text-black mb-2">
                        Si precisa che il Direttore Tecnico della Diagnostica per Immagini potrà accedere – solo su richiesta del paziente e nel caso di referti radiologici – all’esame del dato relativo al paziente generato da altro medico radiologo operante in struttura; ciascun medico del Poliambulatorio potrà – solo su richiesta del paziente e per ragioni cliniche – prendere visione dei precedenti referti a sua firma relativi al paziente medesimo.
                    </p>
                    <p className="text-base font-normal text-black">
                        Il servizio di analisi di laboratorio potrà avvalersi di laboratori esterni per l’esecuzione di esami in outsourcing, opportunamente nominati responsabili esterni al trattamento.
                    </p>
                </div>

                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Natura del trattamento dati</div>
                    <p className="text-base font-normal text-black">
                        Il conferimento dei dati è da ritenersi facoltativo, ma l’eventuale rifiuto determinerà l’impossibilità a dar corso alle attività in oggetto.
                    </p>
                    <p className="text-base font-normal text-black">
                        Il trattamento delle informazioni che La riguardano, sarà improntato ai principi di correttezza, liceità e trasparenza e tutelando la riservatezza e i tuoi diritti.
                    </p>
                    <p className="text-base font-normal text-black">
                        I trattamenti dei dati personali avranno luogo presso la sede operativa di Villa Iris s.r.l. in Via Cesare Pavese n. 12, 10044 Pianezza (TO).
                    </p>
                    <p className="text-base font-normal text-black">
                        Il soggetto che effettua la registrazione dà liberamente il consenso al trattamento dei dati, consapevole che in assenza di tale consenso la registrazione e la sottoscrizione dei servizi non possono aver luogo.
                    </p>
                </div>

                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Comunicazione dei dati</div>
                    <p className="text-base font-normal text-black mb-2">
                        Si rende noto che i dati acquisiti potranno essere comunicati, nell’ambito delle rispettive competenze istituzionali e di specifica pertinenza, nei confronti di:
                    </p>

                    <ul className="list-disc list-inside mb-2">
                        <li className="font-normal pl-2">
                            <span>ASL (Azienda Sanitaria Locale)</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>C.S.I. Piemonte (SAR) </span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>Servizio Sanitario Nazionale</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>Enti previdenziali (solo dati anagrafici)</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>Casse di previdenza (solo dati anagrafici)</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>Compagnie Assicurative</span>
                        </li>
                    </ul>
                    <p className="text-base font-normal text-black mb-2">
                        Ricordiamo altresì che sarà consentito, nei casi espressamente previsti per Legge, l’accesso agli archivi agli organi sanitari di vigilanza a fini ispettivi e alla polizia giudiziaria ai fini investigativi.
                    </p>

                </div>

                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Periodo di conservazione</div>
                    <p className="text-base font-normal text-black mb-2">
                        Tutti i dati, conservati sia su supporto cartaceo che informatico, saranno conservati per un periodo di 10 anni per l’attività ambulatoriale e per tutta la durata dell’attività della struttura per l’attività di degenza, e successivamente verranno cancellati/distrutti secondo quanto previsto dalla normativa vigente.
                    </p>
                </div>
                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Diffusione dei dati ed eventuale Trasferimento in Paesi Extra UE</div>
                    <p className="text-base font-normal text-black mb-2">
                        I dati personali ad Ella riferibili non sono soggetti a diffusione. Le rendiamo noto che detto i suoi dati non verranno trasferiti verso paesi Terzi.
                    </p>
                </div>
                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Videosorveglianza</div>
                    <p className="text-base font-normal text-black mb-2">
                        All’interno della Struttura è attivo un Sistema di videosorveglianza finalizzato a presidiare la sicurezza nonché il patrimonio aziendale. Le immagini registrate vengono trattate nel rispetto della normativa vigente nonché dei provvedimenti emessi dall’Autorità Nazionale Garante della Privacy.
                    </p>
                </div>
                <div className="space-y-4 mb-4">
                    <div className="font-bold text-blue-primary ">Diritti dell’interessato</div>
                    <p className="text-base font-normal text-black mb-2">
                        Le comunichiamo infine che i diritti ad Ella riconosciuti dalla legge in relazione ai Suoi dati personali sono quelli di cui agli articoli da 13 a 21 del Regolamento UE n. 679 del 27 aprile 2016 ovvero il diritto di accedere ai Suoi dati personali e alle informazioni relative agli stessi; il diritto di chiedere l’aggiornamento, la rettifica o l’integrazione dei dati; il diritto di richiedere la cancellazione dei dati personali alle condizioni di legge; il diritto di richiedere la limitazione del trattamento nei casi previsti dalla legge; il diritto di richiedere la trasmissione dei Suoi dati ad altro Titolare in formato leggibile (cd. diritto alla portabilità); il diritto di opporsi al trattamento qualora ricorrano determinate condizioni di legge; il diritto di revocare il consenso in qualsiasi momento limitatamente alle ipotesi in cui il trattamento sia basato sul Suo consenso (in altre parole qualora il trattamento dei Suoi dati personali avvenga in forza del consenso al trattamento da Ella rilasciato, Le è riconosciuto il diritto di revocare il consenso in qualsiasi momento senza peraltro pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca); il diritto di presentare reclamo all’Autorità garante per la protezione dei dati personali -i cui recapiti sono rinvenibili sul sito www.garanteprivacy.it– in caso di trattamento illecito o di ritardo nella risposta del Titolare ad una richiesta riguardante i Suoi diritti]. Il testo completo dei menzionati articoli del Regolamento UE 2016/679 è a Sua disposizione presso l’ufficio accettazione ricoveri nonché appeso alla bacheca adiacente all’entrata degli uffici amministrativi. La richiesta per l’esercizio dei diritti di cui sopra deve essere presentata per iscritto ed indirizzata al Titolare del trattamento.
                        {' '}
                        <a href="http://www.garanteprivacy.it"
                            className="text-base font-medium underline-none text-blue-primary text-right">www.garanteprivacy.it</a>{' '}
                        – in caso di trattamento illecito o di ritardo nella risposta del Titolare ad una richiesta riguardante i Suoi diritti]. Il testo completo dei menzionati articoli del Regolamento UE 2016/679 è a Sua disposizione presso l’ufficio accettazione ricoveri nonché appeso alla bacheca adiacente all’entrata degli uffici amministrativi. La richiesta per l’esercizio dei diritti di cui sopra deve essere presentata per iscritto ed indirizzata al Titolare del trattamento.
                    </p>
                    <p className="text-base font-normal text-black mb-2">
                        Segnaliamo inoltre che il Regolamento Privacy UE n. 679/2016, per quanto riguarda i dati personali relativi -tra l’altro- alla salute o alla vita sessuale od orientamento sessuale della persona, consente il relativo trattamento in ambito sanitario se necessario per la diagnosi, l’assistenza o terapia sanitaria o sociale sempreché il trattamento avvenga da parte (o sotto la responsabilità) di un professionista soggetto al segreto professionale. Entro i descritti limiti il trattamento dei dati personali relativi alla salute può avvenire senza il consenso dell’interessato [art. 9, comma secondo, lett. h) del Regolamento UE n. 679/2016]. E’ fatto salvo -peraltro- il diritto del Singolo Stato membro dell’Unione Europea di mantenere o introdurre ulteriori condizioni per il trattamento dei dati personali genetici, biometrici o relativi alla salute. Il nostro legislatore –avvalendosi di tale facoltà- ha previsto all’art. 2 septies del Codice Privacy (D. Lgs. n. 196 del 2003 come modificato per effetto del D. Lgs. n. 101 del 2018) l’introduzione di ulteriori misure di garanzia che, per i dati genetici, possono consistere in prospettiva futura nella reintroduzione del consenso dell’interessato quale condizione legittimante il trattamento. Pertanto, quanto sopra premesso e da ultimo precisato.
                    </p>
                </div>












                {/* <p className="text-base font-normal text-black mb-4">
                    I dati personali idonei a rilevare il Suo stato di salute Le saranno notificati dal Direttore Sanitario della struttura e
                    {' '}
                    <span className="font-bold text-blue-primary"> Responsabile del trattamento</span>{' '}
                    dei dati personali il dott. Guadagnuolo Luigi o dalla dott.ssa Frieri Tiziana.
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Quanto alla {' '}
                    <span className="font-bold text-blue-primary">Diffusione dei dati</span>{' '}
                    ed il loro eventuale {' '}
                    <span className="font-bold text-blue-primary">Trasferimento in Paesi Extra UE,</span>{' '}
                    Le comunichiamo che i dati personali ad Ella riferibili non sono soggetti a diffusione. Le rendiamo noto che i suoi dati non verranno trasferiti verso paesi Terzi.
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Le comunichiamo infine che i {' '}
                    <span className="font-bold text-blue-primary">diritti</span>{' '}
                    ad Ella riconosciuti dalla legge in relazione ai Suoi dati personali sono quelli di cui agli articoli da 14 a 21 del Regolamento UE n. 679 del 27 aprile 2016 ovvero il diritto di accedere ai Suoi dati personali e alle informazioni relative agli stessi; il diritto di chiedere l’aggiornamento, la rettifica o l’integrazione dei dati; il diritto di richiedere la cancellazione dei dati personali alle condizioni di legge; il diritto di richiedere la limitazione del trattamento nei casi previsti dalla legge; il diritto di richiedere la trasmissione dei Suoi dati ad altro Titolare in formato leggibile (cd. diritto alla portabilità); il diritto di opporsi al trattamento qualora ricorrano determinate condizioni di legge; il diritto di revocare il consenso in qualsiasi momento limitatamente alle ipotesi in cui il trattamento sia basato sul Suo consenso (in altre parole qualora il trattamento dei Suoi dati personali avvenga in forza del consenso al trattamento da Ella rilasciato, Le è riconosciuto il diritto di revocare il consenso in qualsiasi momento senza peraltro pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca); il diritto di presentare reclamo all’Autorità garante per la protezione dei dati personali -i cui recapiti sono rinvenibili sul sito {' '}
                    <a href="http://www.garanteprivacy.it" className="text-base font-medium text-blue-primary text-right no-underline">
                        www.garanteprivacy.it
                    </a>{' '}
                    – in caso di trattamento illecito o di ritardo nella risposta del Titolare ad una richiesta riguardante i Suoi diritti].
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Il testo completo dei menzionati articoli del Regolamento UE 2016/679 è a Sua disposizione presso la bacheca posta in prossimità degli uffici amministrativi e sul sito del Garante.
                </p>

                <p className="text-base font-normal text-black mb-4">
                    La richiesta per l’esercizio dei diritti di cui sopra deve essere presentata per iscritto ed indirizzata al Titolare del trattamento.
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Segnaliamo inoltre che il Regolamento Privacy UE n. 679/2016, per quanto riguarda i dati personali relativi -tra l’altro- alla salute o alla vita sessuale od orientamento sessuale della persona, consente il relativo trattamento in ambito sanitario se necessario per la diagnosi, l’assistenza o terapia sanitaria o sociale sempreché il trattamento avvenga da parte (o sotto la responsabilità) di un professionista soggetto al segreto professionale. Entro i descritti limiti il trattamento dei dati personali relativi alla salute può avvenire senza il consenso dell’interessato [art. 9, comma secondo, lett. h) del Regolamento UE n. 679/2016. E’ fatto salvo -peraltro- il diritto del Singolo Stato membro dell’Unione Europea di mantenere o introdurre ulteriori condizioni per il trattamento dei dati personali genetici, biometrici o relativi alla salute. Il nostro legislatore –avvalendosi di tale facoltà- ha previsto all’art. 2 {' '}
                    <i>
                        septies
                    </i>{' '}
                    del Codice Privacy (D. Lgs. n. 196 del 2003 come modificato per effetto del D. Lgs. n. 101 del 2018) l’introduzione di ulteriori misure di garanzia che, per i dati genetici, possono consistere in prospettiva futura nella reintroduzione del consenso dell’interessato quale condizione legittimante il trattamento.&nbsp;
                </p> */}
            </div>
        </div>
    )
}

export default PrivacyPolicy;