import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AmministrazioneTrasparente = () => {

    return (
        <main id="amministrazione_trasparente" className="mt-0 mb-0 z-20 relative">
            {/* <!-- Hero Image --> */}
            <div className="flex flex-col items-center justify-between mb-0">
                <div className="flex w-full">
                    {/* <img
                        loading="lazy"
                        src="/images/sec_header.png"
                        className="w-full"
                    /> */}
                </div>
            </div>

            <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">
                {/* Header Logo */}
                {/* <div className="absolute top-0 md:top-10 left-8 xl:left-24 z-20">
                    <Link to={"/"} className="">
                        <img
                            loading="lazy"
                            src="/images/villairis2.png"
                            className="w-1/3 lg:w-3/6 3xl:w-11/12"
                        />
                    </Link>
                </div> */}

                <div className="w-11/12 flex flex-col my-12">
                    <p className="w-full mx-auto text-left text-blue-primary font-normal text-3xl lg:text-4xl mt-6 mb-8 lg:mx-0">
                        AMMINISTRAZIONE <span className="font-bold">TRASPARENTE</span>
                    </p>
                    <div className="w-full flex flex-col-reverse justify-between gap-8 lg:gap-16 lg:flex-row">
                        {/* <!-- Content Left --> */}
                        <div data-aos="fade-right" data-aos-duration="1500" className="flex flex-col justify-between lg:w-1/2">
                            <div className="flex flex-col justify-start gap-2">
                                <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/VILLA-IRIS-griglia-rilevazione.pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    GRIGLIA DI RILEVAZIONE
                                </a>
                                <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/VILLA-IRIS-II-Attestazione.pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    ATTESTAZIONE DI ASSOLVIMENTO
                                </a>
                                <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/Bilancio-2022-Villa-Iris-s.r.l..pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    BILANCIO DI ESERCIZIO
                                </a>
                                <div>
                                    <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                        COSTI CONTABILIZZATI DEI SERVIZI EROGATI ALL’UTENTE
                                    </p>
                                    <p className="text-base font-normal text-black">
                                        I costi contabilizzati dei servizi erogati sono riportati nel Bilancio di esercizio
                                        inserito nella voce “Bilancio”.
                                    </p>
                                </div>

                                <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/Tempi+di+attesa_01_07_24.pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    TEMPI DI ATTESA
                                </a>
                                <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/Report-qualitativo-Villa-Iris.pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    REPORT QUALITATIVO
                                </a>
                                <a href="https://poliambulatorio.s3.us-west-2.amazonaws.com/Carta_Servizi_Villa+Iris_2024.pdf" target="_blank" className="!leading-10 w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    CARTA DEI SERVIZI
                                </a>
                            </div>
                        </div>

                        {/* <!-- Content Right --> */}
                        <div data-aos="fade-left" data-aos-duration="1500" className="relative flex flex-col justify-start items-center gap-4 lg:w-1/2">
                            <div className="space-y-0 w-full">
                                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl underline lg:mt-0 lg:mx-0">
                                    ACCESSO CIVICO
                                </p>
                                <p className="text-base text-justify font-normal text-black">
                                    Il diritto all’accesso civico consiste nella possibilità, per i cittadini, di accedere a dati, documenti e informazioni detenuti dalle pubbliche amministrazioni o dai concessionari
                                    di servizi pubblici.<br />
                                    Tale diritto riguarda tanto i dati che sono oggetto di pubblicazione obbligatoria (laddove i medesimi dati non risultino pubblicati), quanto ogni altro dato (in tale ultima ipotesi nei limiti previsti dalla legge a tutela di altri interessi pubblici e/o privati: articolo 5 bis del d.lgs. n. 33/2013).<br />
                                    Le strutture sanitarie accreditate e a contratto con il Servizio Sanitario sono soggette al diritto di accesso civico in relazione ai soli dati, documenti e informazioni relativi allo svolgimento dell’attività di assistenza sanitaria.<br />
                                    La richiesta deve consentire alla struttura di individuare il dato, il documento o l’informazione che si intende acquisire; sono pertanto inammissibili le richieste generiche. Nel caso di richiesta relativa a un numero manifestamente irragionevole di documenti, tale da imporre un carico di lavoro suscettibile di compromettere il buon funzionamento della struttura sanitaria, la stessa può respingerla per tutelare il buon andamento dell’attività amministrativa (Linee guida Agenzia nazionale anticorruzione-ANAC su accesso civico generalizzato, paragrafo 4.2).<br />
                                    Di seguito si riporta il recapito dell’ufficio a cui indirizzare la richiesta:
                                </p>
                                <a href="mailto:amministrazione@villairis.srl" className="text-base font-normal text-blue-primary text-left no-underline">amministrazione@villairis.srl</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AmministrazioneTrasparente;
